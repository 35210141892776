import React from "react";
import { Map, GoogleApiWrapper, InfoWindow } from "google-maps-react";
import { withStyles } from "@material-ui/core/styles";
import styles from "../../assets/Table";
import { Grid, Fab, Drawer } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import SearchBar from "../../components/Map/searchbox";
import {
  FormuCliente,
  ContentInfo,
  PintarAutos,
  ListarConductores,
  DataDriver,
} from "./procesos";
import {
  Autos,
  EnviarSolicitud,
  cancelRequest,
  ConsultaToken,
} from "./consultas";
import io from "socket.io-client";
import { openSnackbar } from "../../components/msg/msg";
import { url_socket } from "../../config";
const YOUR_API_KEY = "AIzaSyBJgnSGRM-P3ou_m8ygFD5_sz_OqOcC2tE"; //api key cuenta Cooptranschoco
export class Mapa extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showingInfoWindow: false,
      activeMarker: {},
      lat: 0,
      lng: 0,
      autos: [],
      info: [],
      zoom: 15,
      Drawer: false,
      Drawer_cliente: false,
      pathCoordinates: [],
      formu_cliente: {
        nombre: "",
        apellido: "",
        celular: "",
        email: "",
        vehiculo: "",
      },
      btn_solicitud: {
        option: true,
        color: "grey",
      },
      estado_solicitud: "",
      datos_conductor: {},
      DataDriver: false,
      panic: {},
    };
    this.marcas_mapa = [];
    this.EnviarSolicitud = EnviarSolicitud;
    this.cancelRequest = cancelRequest;
    this.tipveh_list = {};
    this.socket = io(url_socket, { jsonp: false });
    this.socket.on("connect", async () => {
      const userToken = await this.props.cookies.get("token_auth");
      this.socket.emit("Auth", userToken);
    });
    this.socket.on("cancelDriver", (data) => {
      data.intentos++;
      if (data.driver[data.intentos] !== undefined) {
        this.socket.emit("findDriver", data);
        setTimeout(() => {
          this.socket.emit("cancelDriver", data);
        }, 10000);
      } else {
        this.setState({
          estado_solicitud: "",
        });
        // openSnackbar({
        //   message:
        //     "No hay más vehículos en la zona, intente más tarde por favor.",
        //   type: "error",
        // });
      }
    });
    this.socket.on("getDriver", (data) => {
      this.setState({
        estado_solicitud: "A",
        datos_conductor: data,
        DataDriver: true,
        Drawer_cliente: false,
        formu_cliente: {},
      });
    });
    this.socket.on("confirmPickup", (data) => {
      console.log(data);
    });
    this.socket.on("panic_alert", (data) => {
      ConsultaToken(this, data);
    });
  }

  componentDidMount() {
    navigator.geolocation.watchPosition((position) => {
      this.setState({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    });
    this.AjusteMapa();
    Autos(this);
  }

  AjusteMapa = () => {
    let ventana = document.querySelectorAll("[class^=Mapa-root-]")[0];
    if (ventana !== undefined) {
      if (ventana.childNodes[0] !== undefined) {
        ventana.childNodes[0].style["width"] = "80%";
        ventana.childNodes[0].style["height"] = "80%";
      }
    }
  };

  onMarkerClick = (props, marker, e) => {
    this.setState({
      info: props.info,
      activeMarker: marker,
      showingInfoWindow: true,
    });
  };

  MarcaCargada = (data) => {
    if (data !== null) {
      const { props, marker } = data;
      this.marcas_mapa[props.info.numdoc] = marker;
    }
  };

  onClose = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
        zoom: 15,
      });
    }
    if (this.state.Drawer) {
      this.setState({
        Drawer: false,
      });
    }
    if (this.state.Drawer_cliente) {
      this.setState({
        Drawer_cliente: false,
      });
    }
  };

  ChangeData = (data) => {
    let formu_cliente = {
      ...this.state.formu_cliente,
      [data.target.id]: data.target.value,
    };
    this.setState({ formu_cliente });
    this.ValidateFields(formu_cliente);
  };

  ValidateFields = (formu_cliente) => {
    const { nombre, apellido, celular, email, vehiculo } = formu_cliente;
    if (
      nombre !== "" &&
      apellido !== "" &&
      celular !== "" &&
      email !== "" &&
      vehiculo !== ""
    ) {
      this.setState({
        btn_solicitud: {
          option: false,
          color: "#31a031",
        },
      });
    } else {
      this.setState({
        btn_solicitud: {
          option: true,
          color: "grey",
        },
      });
    }
  };

  SetDirecciones = async (data, id) => {
    if (id === "direccion_origen") {
      this.setState({
        formu_cliente: {
          ...this.state.formu_cliente,
          direccion_origen: await data.formatted_address,
          data_map_origen: await data,
        },
      });
    } else if (id === "direccion_destino") {
      this.setState({
        formu_cliente: {
          ...this.state.formu_cliente,
          direccion_destino: await data.formatted_address,
          data_map_destino: await data,
        },
      });
    }
    if (
      this.state.formu_cliente.direccion_destino !== undefined &&
      this.state.formu_cliente.direccion_origen !== undefined
    ) {
      this.setState({ Drawer_cliente: true });
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <Grid container>
          <Grid item xs={5}>
            <SearchBar
              {...this.props}
              id={"direccion_origen"}
              lat={this.state.lat}
              lng={this.state.lng}
              Funcion={this.SetDirecciones}
              placeholder="Dirección origen "
            />
          </Grid>
          <Grid item xs={5}>
            <SearchBar
              {...this.props}
              id={"direccion_destino"}
              lat={this.state.lat}
              lng={this.state.lng}
              Funcion={this.SetDirecciones}
              placeholder="Dirección destino"
            />
          </Grid>
          <Grid item xs={2}>
            <Fab
              variant="extended"
              style={{ backgroundColor: "#31a031", color: "white" }}
              onClick={() => {
                this.setState({ Drawer: true });
              }}
            >
              {"Lista Conductores"}
              <ArrowBack />
            </Fab>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.root}>
              <Map
                google={this.props.google}
                zoom={this.state.zoom}
                center={{
                  lat: parseFloat(this.state.lat),
                  lng: parseFloat(this.state.lng),
                }}
                fullscreenControl={false}
                zoomControl={false}
                mapTypeControl={false}
                streetViewControl={false}
                minZoom={14}
              >
                {Autos(this)}
                {PintarAutos(this)}
                <InfoWindow
                  marker={this.state.activeMarker}
                  visible={this.state.showingInfoWindow}
                  onClose={this.onClose}
                >
                  <ContentInfo {...this} />
                </InfoWindow>
              </Map>
            </div>
          </Grid>
        </Grid>
        <Drawer anchor="right" open={this.state.Drawer} onClose={this.onClose}>
          {ListarConductores(this)}
        </Drawer>
        <Drawer
          anchor="bottom"
          open={this.state.Drawer_cliente}
          onClose={this.onClose}
        >
          {FormuCliente(this)}
        </Drawer>
        {DataDriver(this)}
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: YOUR_API_KEY,
})(withStyles(styles)(Mapa));
